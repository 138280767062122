.control {
    &[data-active] {
        background: #ddd !important;
    }

    &:hover {
      color: #000;
    }
  
    &[data-active] > label > span {
      color: #000;
    }
}
